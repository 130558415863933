import React, {Fragment, ReactNode, useRef} from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { FormattedMessage } from 'react-intl';
import {Icon} from "./Icon";


type Props = {
    toggle: (value: boolean) => void,
    title: string,
    className?: string,
    content: ReactNode,
    children?: ReactNode,
    big?: boolean,
    xl?: boolean,
    isOpen: boolean,
}


export const Modal: (props: Props) => JSX.Element = ({toggle, title, className, content, big, xl, children, isOpen}) => {

    let modalFocusBtnRef = useRef(null);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className={'fixed inset-0 z-30 overflow-y-auto ' + (className && className)}
                onClose={toggle}
                initialFocus={modalFocusBtnRef}
            >
                <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-sky-500 opacity-30" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
              &#8203;
            </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={'inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl border-none ' + (big ? 'max-w-2xl' : (xl ? 'max-w-6xl' : 'max-w-md'))}>
                            <div className="flex justify-end">
                                <Icon type="close" className="cursor-pointer" onClick={() => toggle(false)} />
                            </div>
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900 mb-5"
                            >
                                <FormattedMessage id={title} />
                            </Dialog.Title>
                            {children && children}
                            {content}
                            <button ref={modalFocusBtnRef} className="invisible" />
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

// Modal.propTypes = {
//     isOpen: PropTypes.bool.isRequired,
//     toggle: PropTypes.func.isRequired,
//     title: PropTypes.string.isRequired,
//     content: PropTypes.element.isRequired,
//     big: PropTypes.bool,
//     className: PropTypes.string
// };
