import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormInputWrapper } from './FormInputWrapper';
import { useField } from 'formik';

type Props = {
    checked: number,
    noInitValue?: boolean,
    column?: boolean,
    disabled?: boolean,
    onChange?: Function,
    className?: string,
    name: string,
    label?: string,
    items: any[],
}


export const RadioButton: (props: Props) => JSX.Element = ({
                                                           checked,
                                                           noInitValue,
                                                           column,
                                                           disabled,
                                                           items,
                                                           onChange,
                                                           name,
                                                           label,
                                                           className}) => {

    const [checkedIdx, setCheckedIdx] = useState<number | null>(0);
    const [ItemComponent, setItemComponent] = useState<React.ReactNode>('React.Fragment');
    const [field, meta] = useField(name);

    useEffect(() => {
        if (checked && checked <= items.length) {
            setCheckedIdx((checked));
        } else if (noInitValue) {
            setCheckedIdx(null);
        }
    }, [checked]);

    useEffect(() => {
        if (column !== undefined) {
            setItemComponent('div');
        } else {
            setItemComponent('React.Fragment');
        }
    }, [column]);

    const handleClick = (idx: number) => {
        if (disabled) {
            return;
        }

        setCheckedIdx(idx);

        if (onChange !== undefined) {
            onChange(idx);
        }
    };

    return <FormInputWrapper
            meta={meta}
            name={name}
            label={label}
        >
            <div className={className}>
                {items.map((item, idx) => {
                    return <div key={idx} className="mr-10">
                        <div className="items-center mb-4">
                            <input id={name + '_' + idx} type="radio" value={idx} name={name} checked={checkedIdx === idx}
                                   onChange={() => handleClick(idx)}
                                   className="cursor-pointer w-5 h-5 text-bk-p bg-gray-100 border-gray-100 focus:ring-bk-p dark:focus:ring-bk-p dark:ring-offset-white focus:ring-1 dark:white dark:border-gray-300" />
                                <label htmlFor="default-radio-1"
                                       className="ml-2 text-sm font-medium text-bk-grey-300">
                                    <FormattedMessage id={item} defaultMessage={item}/>
                                </label>
                        </div>
                    </div>
                })}
            </div>
    </FormInputWrapper>;

};
