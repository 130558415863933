import React from 'react';
import { FormError, FormLabel } from "./";


export const FormInputWrapper = (props) => {

    // Other half of props.labelWidth
    const inputWidth = {
        'w-1/2': 'w-1/2',
        'w-1/3': 'w-2/3',
        'w-1/4': 'w-3/4',
        'w-1/5': 'w-4/5',
        'w-1/6': 'w-5/6',
        'w-2/3': 'w-1/3',
        'w-2/4': 'w-2/4',
        'w-2/5': 'w-3/5',
        'w-2/6': 'w-4/6',
        'w-3/4': 'w-1/4',
        'w-3/5': 'w-2/5',
        'w-3/6': 'w-3/6',
        'w-4/5': 'w-1/5',
        'w-4/6': 'w-2/6',
        'w-5/6': 'w-1/6',
        'w-auto': 'w-full'
    }

    return (
        <div className={(props.width || '') + (props.className ? ' ' + props.className : '')}>
            <div className={(props.labelPosition && props.labelWidth ? ' flex items-center h-full' : 'h-full')}>
                {(props.label && !props.noLabel) &&
                    <div className={'h-auto ' + (props.labelPosition && props.labelWidth ? props.labelPosition === 'left' ? 'order-1 ' + props.labelWidth : 'order-2 :' + props.labelWidth : '')}>
                        <FormLabel labelValues={props.labelValues} label={props.label} name={props.name} labelPosition={props.labelPosition}/>
                    </div>
                }
                <div className={'h-full ' + (props.labelPosition && props.labelWidth ? props.labelPosition === 'left' ? 'order-2 ' + inputWidth[props.labelWidth] : 'order-1 :' + inputWidth[props.labelWidth] : '')}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

// FormInputWrapper.propTypes = {
//     name: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     meta: PropTypes.object.isRequired,
//     className: PropTypes.string,
//     labelPosition: PropTypes.oneOf(['left', 'right']),
//     labelWidth: PropTypes.oneOf(['w-1/2', 'w-1/3', 'w-1/4', 'w-1/5', 'w-1/6', 'w-2/3', 'w-2/4', 'w-2/5', 'w-2/6', 'w-3/4', 'w-3/5', 'w-3/6', 'w-4/5', 'w-4/6', 'w-5/6', 'w-auto'])
// }
