import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../_actions';
import { Link, Text, LanguageSwitcher, ClientSwitcher } from './';
import { ReactComponent as Logo } from '../assets/img/icons/Logo.svg';
import { ReactComponent as Logout } from '../assets/img/icons/Logout.svg';
import { useAppSelector } from '../_library';
import { useLocation } from 'react-router-dom';

type Props = {};

export const Navbar: (props: Props) => JSX.Element | null = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const user = useAppSelector(state => state.user);
    const [synchronized, setSynchronized] = useState(false);

    const renderNavItem = (link: string, label: string) => (
        <Link
            to={link}
            secondary={true}
            className={
                'underline-offset-[10px] decoration-2 block mt-4 lg:inline-block lg:mt-0 mr-4 last:mr-0 ' +
                (location.pathname.startsWith(link) ? 'underline !text-bk-pink-200' : '')
            }
            label={label}
        />
    );

    useEffect(() => {
        if (user.synchronized) {
            setSynchronized(true);
        }
    }, [user]);

    if (!synchronized) {
        return null;
    }

    return(
        <>
            <nav className="flex items-center justify-between flex-wrap p-8">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <Logo />
                </div>
                <div className="block lg:hidden">
                    <button
                        className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                        </svg>
                    </button>
                </div>
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <div className="text-lg lg:flex-grow">
                        {renderNavItem('/dashboard', 'bk.navbar.dashboard')}
                        {renderNavItem('/transactions', 'bk.navbar.transactions')}
                        {renderNavItem('/users', 'bk.navbar.users')}
                        {renderNavItem('/messaging', 'bk.navbar.messaging')}
                        {user.availableFeatures && ((user.availableFeatures['reporting'] && user.availableFeatures['reporting']['available']) || user.availableFeatures['all_features']) ?
                            <>{renderNavItem('/reporting', 'bk.navbar.reporting')}</>
                          : null
                        }
                    </div>
                    {/*<div>*/}
                    {/*    <div className="mr-6">*/}
                    {/*        <Text className="inline-block text-base px-4 py-2 leading-none border rounded">*/}
                    {/*            {user.clients.find((c: any) => c.id === user.currentClient)?.name}*/}
                    {/*        </Text>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div>
                        <ClientSwitcher />
                    </div>
                    <div>
                        <LanguageSwitcher/>
                    </div>
                    <div>
                        <div className="flex mt-1 cursor-pointer" onClick={() => (userActions.logout())(dispatch)}>
                            <div className="">
                                <Logout/>
                            </div>
                            <Text className="ml-2" label="bk.dashboard.logout"/>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );

};
