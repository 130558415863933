import { i18nConstants } from '../_constants';
import { StoreDispatchType } from "../_library";


export const i18nActions = {
    changeLanguage
};

function changeLanguage(lang: string) {
    return (dispatch: StoreDispatchType) => {
        dispatch((data => {
            return { type: i18nConstants.CHANGE_LANGUAGE, data }
        })({lang}));
    };
}
