import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import '../assets/calendar.css'
import RCalendar from 'react-calendar';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

type Props = {
    value: Value, 
    onChange: (value: Value) => void
};

export const Calendar: (props: Props) => JSX.Element =({value, onChange}) => {    
    return <RCalendar maxDate={new Date()} selectRange={true} onChange={onChange} value={value} />;
};
