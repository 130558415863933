import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RRDLink } from 'react-router-dom';

type Props = {
    children?: any,
    label?: string,
    className?: string,
    targetBlank?: boolean,
    secondary?: boolean,
    dark?: boolean
} & (
    {
        external?: never | false,
        to: never,
        onClick: () => void
    } |
    {
        external?: true,
        to: string,
        onClick?: () => void
    }
    );

export const Link: (props: Props) => JSX.Element =
    ({
         children,
         label,
         external,
         to,
         className,
         targetBlank,
         onClick,
         secondary,
         dark
     }) => {

    let childrenElems = children;

    if (label) {
        childrenElems = <FormattedMessage id={ label } />;
    }

    const parseExternalLink = (link: string) => {
        if (link && !link.includes('http') && !link.includes('https')) {
            link = 'http://' + link;
        }

        return link;
    };

    let colors = 'text-bk-p hover:text-bk-p-l '

    if (secondary) colors = 'text-bk-grey-100 hover:text-bk-pink-200 ';
    if (dark) colors = 'text-bk-black hover:text-bk-black ';

    if (external) {
        return <a href={parseExternalLink(to)} className={'font-medium hover:underline ' + colors + (className || '')}
                  {...(targetBlank ? {target: '_blank'} : {})}>
            {childrenElems}
        </a>;
    }

    if (onClick !== undefined) {
        return <span onClick={onClick} className={'font-medium hover:underline cursor-pointer ' + colors + (className || '')}>
            {childrenElems}
        </span>;
    }

    return <RRDLink to={to} className={'font-medium hover:underline ' + colors + (className || '')}>
        {childrenElems}
    </RRDLink>;

};
