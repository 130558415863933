import React, { useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import { Text, FormInputWrapper, FormFileInputImage, FormFileInputText, Icon } from './';
import Files from 'react-files';


export const FormFileInput =
    ({
         name,
         onChange,
         className,
         width,
         label,
         preview = true,
         accepts,
         formats,
         maxSize
     }) => {

        const [field, meta] = useField(name);
        const [fileName, setFileName] = useState('');

        const getBase64 = file => {
            return new Promise(resolve => {
                let baseURL = "";
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    baseURL = reader.result;
                    resolve(baseURL);
                };
            });
        };

        const handleFileSelect = async file => {
            try {
                setFileName(file[0].name);
                const fileInBase64 = await getBase64(file[0]);
                onChange(name, fileInBase64, true);
            } catch (e) {
                console.log('failed converting file to base64', e);
            }
        };

        return (
            <FormInputWrapper className={className} width={width} meta={meta} name={name}>
                <Text label={label} />
                {field.value ?
                    <div className={'max-h-96 rounded-[6px] border-2 border-dashed border-gray-300 mt-2 relative ' + (preview ? '' : 'h-12')}>
                        {preview ?
                            <div className="max-h-80 overflow-hidden overflow-scroll">
                                <img className="h-fit" src={field.value} />
                            </div>
                            :
                            <div>
                                {fileName}
                            </div>
                        }
                        <div
                            className="absolute right-1 top-1 cursor-pointer rounded-full p-1 bg-bk-grey-light z-10"
                            onClick={() => onChange(name, '')}
                        >
                            <Icon type="close" />
                        </div>
                    </div>
                    :
                    <Files
                        {...field}
                        onChange={handleFileSelect}
                        // onError={handleError}
                        accepts={accepts ? accepts : ['image/*', '.pdf']}
                        multiple={false}
                        maxFileSize={10000000} //10MB
                        minFileSize={0}
                        clickable>
                        <div className="flex flex-col items-center gap-[4px] pt-[22px] pb-[26px] px-[26px] relative rounded-[6px] border-2 border-dashed border-gray-300">
                            <FormFileInputImage />
                            <div className="inline-flex items-center gap-[4px] relative flex-[0_0_auto]">
                                <FormFileInputText className="!flex-[0_0_auto]" state="default" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:var(--text-sm-leading-5-font-medium-font-family)] font-[number:var(--text-sm-leading-5-font-medium-font-weight)] text-[color:var(--gray-600)] text-[length:var(--text-sm-leading-5-font-medium-font-size)] tracking-[var(--text-sm-leading-5-font-medium-letter-spacing)] leading-[var(--text-sm-leading-5-font-medium-line-height)] whitespace-nowrap ">
                                    or drag and drop
                                </div>
                            </div>
                            <p className="relative w-fit [font-family:var(--text-xs-leading-4-font-normal-font-family)] font-[number:var(--text-xs-leading-4-font-normal-font-weight)] text-bk-grey-light text-[length:var(--text-xs-leading-4-font-normal-font-size)] text-center tracking-[var(--text-xs-leading-4-font-normal-letter-spacing)] leading-[var(--text-xs-leading-4-font-normal-line-height)] whitespace-nowrap ">
                                {formats ?
                                    <>{formats}</>
                                    :
                                    <>PNG, JPG, GIF</>
                                }
                                &nbsp;up to {maxSize ? <>{maxSize / 1000000}MB</> : <>10MB</>}
                            </p>
                        </div>
                    </Files>
                }
                <Text left sm className="text-red-500 sm:text-sm">
                    <ErrorMessage name={name} />
                </Text>
            </FormInputWrapper>
        );
    };


// FormFileInput.propTypes = {
//     name: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
//     btnLabel: PropTypes.string.isRequired,
//     meta: PropTypes.object.isRequired,
//     className: PropTypes.string,
//     width: PropTypes.string
// };
