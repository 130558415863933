import PropTypes from "prop-types";
import React from "react";

interface Props {
    state: "default" | "hover" | "focus";
    className: any;
}

export const FormFileInputText = ({ state, className }: Props): JSX.Element => {
    return (
        <div
            className={`inline-flex items-center overflow-hidden rounded-[6px] bg-[color:var(--white)] relative ${
                state === "focus"
                    ? "shadow-[var(--focus-ring-2-focus-ring-offset-2-focus-ring-offset-white-focus-ring-indigo-500)]"
                    : ""
            } ${className}`}
        >
            <div
                className={`[font-family:var(--text-sm-leading-5-font-medium-font-family)] w-fit mt-[-1.00px] tracking-[var(--text-sm-leading-5-font-medium-letter-spacing)] text-[length:var(--text-sm-leading-5-font-medium-font-size)]  relative font-[number:var(--text-sm-leading-5-font-medium-font-weight)] whitespace-nowrap leading-[var(--text-sm-leading-5-font-medium-line-height)] ${
                    state === "hover" ? "text-bk-p-l" : "text-bk-p"
                }`}
            >
                Upload a file
            </div>
        </div>
    );
};

FormFileInputText.propTypes = {
    state: PropTypes.oneOf(["default", "hover", "focus"]),
};
