import React from 'react';
import { Button, Text } from './';
import {useNavigate} from "react-router-dom";

type Props = {
    mainLabel: string,
    secondLabel?: string,
} & ({
    disableButton?: false,
    buttonLabel: never,
    link: never
} | {
    disableButton?: true,
    buttonLabel: string,
    link: string
});

export const Error: (props: Props) => JSX.Element =
    ({
         mainLabel,
         secondLabel,
         disableButton,
         buttonLabel,
         link
    }) => {

    const navigate = useNavigate();

    return (
        <div className="text-center position-relative">
            <Text center label={mainLabel} className="h3 mt-3" />
            {secondLabel &&
                <Text center label={secondLabel} className="mt-1" />
            }
            {!disableButton &&
                <Button label={buttonLabel} className="mt-3 max-w-xs mx-auto" onClick={() => navigate(link)} />
            }
        </div>
    );
};
