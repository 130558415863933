import moment from 'moment';


export function formatDate(
    dateToFormat: null | undefined | Date,
    withTime = false,
    withMilliseconds = false,
    customFormat: string | undefined = undefined
): string {

    if (dateToFormat === null || !dateToFormat) {
        return '-';
    }

    const date = moment(dateToFormat).local();

    if (customFormat) {
        return date.format(customFormat);
    }

    if (!withTime) {
        return date.format('DD.MM.YYYY');
    }

    if (withMilliseconds) {
        return moment(dateToFormat).local().format('DD.MM.YYYY HH:mm:ss.SSS');
    }

    return date.format('DD.MM.YYYY HH:mm');
}

export function formatNumber(number: null | undefined | string | number, round=false) {

    if (number === undefined || number === null) {
        return '0';
    }

    if (round) {
        number = (Math.round((typeof number === 'string' ? parseFloat(number) : number) * 100) / 100).toFixed(2);
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDateForBackend(date: null | moment.Moment) {
    if (date === null) {
        return null;
    }

    return date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

export const getSignupStepUrlFromKycStatus = (kycStatus: string) => {
    switch(kycStatus) {
        //TODO: michael: add history or do it another way
        case 'general':
            return '/signup/representatives';
        case 'representatives':
            return '/signup/owners';
        case 'owners':
            return '/signup/true_beneficiary';
        case 'true_beneficiary':
            return '/signup/political_importance';
        case 'political_importance':
            return '/signup/financial_information';
        case 'filled':
            return '/dashboard';
        case 'not_filled':
        default:
            return '/signup/general';
    }
}
