import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { FormInputWrapper } from './';
import React from 'react';

type Props = {
    name: string,
    className: string,
    width: string,
    label: string,
    labelPosition: string,
    labelWidth: string,
    onChange: (value: string) => void,
    showLabel: boolean,
    placeholder?: string,
    selected?: boolean,
    options: any[],
    keyName?: string,
    notLabels: boolean
};

export const FormSelect: (props: Props) => JSX.Element =
    ({
         name,
         className,
         width,
         label,
         labelPosition,
         labelWidth,
         onChange,
         showLabel,
         placeholder,
         selected,
         options,
         keyName = 'name',
         notLabels
     }) => {

    const intl = useIntl();
    const [field, meta] = useField(name);

    return(
        <FormInputWrapper
            className={className}
            width={width}
            meta={meta}
            name={name}
            label={label}
            labelPosition={labelPosition}
            labelWidth={labelWidth}
        >
            <select
                {...field}
                id={name}
                name={name}
                {...(onChange ? {onChange: e => onChange(e.target.value)} : {})}
                placeholder={(showLabel && placeholder ? intl.formatMessage({id: placeholder}) : '')}
                className={'h-12 border-bk-grey-light rounded-md placeholder-gray-400 shadow-[0px_1px_2px_0px_rgba(0, 0, 0, 0.05)] text-bk-black focus:ring-transparent focus:outline-none ' + ((meta.value === '' && 'text-gray-400 ') || '') +
                'w-full sm:text-sm ' + (meta.touched && meta.error && 'border-red-500 focus:border-red-600 focus:ring-red-600')}
            >
                <option selected={selected} value="" hidden>{placeholder ? intl.formatMessage({id: placeholder}) : ''}</option>
                {options.map((el, idx) => (
                    <>
                        {notLabels ?
                            <option selected={selected} value={el.value} key={el.value + idx} className={'text-black'}>
                                {el[keyName]}
                            </option>
                            : null
                        }
                        {!notLabels ?
                            <option selected={selected} value={el.value} key={el.value + idx} className={'text-black'}>
                                {el.label ? intl.formatMessage({id: el.label}) : ''}
                            </option> : null
                        }
                    </>
                  )
                )}
            </select>
        </FormInputWrapper>
    );
};

// FormSelect.propTypes = {
//     name: PropTypes.string.isRequired,
//     placeholder: PropTypes.string.isRequired,
//     options: PropTypes.arrayOf(PropTypes.shape({
//         value: PropTypes.string,
//         display: PropTypes.string,
//         name: PropTypes.string
//     })).isRequired,
//     label: PropTypes.string,
//     className: PropTypes.string
// };
