import React, {useState, Fragment, useEffect} from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Button } from './';
import { useDispatch } from 'react-redux';
import { useAppSelector, api } from '../_library';
import { userActions } from '../_actions';
import config from "../config";


export const ClientSwitcher: React.FC = () => {
  const user = useAppSelector(state => state.user);
  console.log('user', user);
  const [selectedCompany, setSelectedCompany] = useState<any>({name: '', id: null});
  const [key, setKey] = useState<number>(0);
  const dispatch = useDispatch();

  const selectClient = (client: { id: number, companyId: string, name: string, role: string }) => {
    api.post('/switch/client', {'companyId': client.companyId})
      .then(res => {
        setSelectedCompany(client);
        setKey(prevKey => prevKey + 1);
        localStorage.setItem(config.accessTokenName, res.data.accessToken);
        (userActions.switchClient(client.id, client.role))(dispatch)
      }).catch();
  };

  useEffect(() => {
    if (user && selectedCompany.id === null) {
      const client = user.clients.find((c: any) => c.id === user.currentClient);

      if (client) {
        setSelectedCompany(client);
      }
    }
  }, [user]);

  return (
    <div className="inline-block mr-6">
      <Popover className="relative" key={key}>
        {({open}) => (
          <>
            <Popover.Button as={Fragment}>
              <Button
                small={true}
                noShadow={true}
                staticLabel={selectedCompany.name}
                color='transparent'
                className="text-base px-2 py-2"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-10 w-auto mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {user.clients.map((client: { id: number, companyId: string, name: string, role: string }) => (
                    <button
                      key={client.id}
                      onClick={() => selectClient(client)}
                      className={`block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${
                        selectedCompany.id === client.id ? 'bg-gray-100' : ''
                      }`}
                    >
                      {client.name}
                    </button>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
