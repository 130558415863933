import React, { useState, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Button, Text } from './';
import { i18nActions } from '../_actions/';
import { useDispatch } from 'react-redux';
import { useAppSelector, api } from '../_library';
import config from '../config';

type LanguageOption = {
    code: string;
    name: string;
};

const languages: LanguageOption[] = [
    { code: 'en', name: 'EN' },
    { code: 'lv', name: 'LV' }
];

export const LanguageSwitcher: React.FC = () => {
    const i18n = useAppSelector(state => state.i18n);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption | any>({ code: i18n.lang, name: i18n.lang.toUpperCase() });
    const [key, setKey] = useState<number>(0);
    const dispatch = useDispatch();

    const selectLanguage = (language: LanguageOption) => {
        api.patch('/users/language', {'language': language.code})
            .then(response => {
                setSelectedLanguage(language);
                setKey(prevKey => prevKey + 1);
                (i18nActions.changeLanguage(language.code))(dispatch)
            }).catch();
    };

    return (
        <div className="inline-block mr-6">
            <Popover className="relative" key={key}>
            {({ open }) => (
                <>
                    <Popover.Button as={Fragment}>
                        <Button
                            small={true}
                            noShadow={true}
                            staticLabel={selectedLanguage.name}
                            color='transparent'
                            className="text-base px-2 py-2"
                        />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute z-10 w-16 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                {languages.map((language) => (
                                <button
                                    key={language.code}
                                    onClick={() => selectLanguage(language)}
                                    className={`block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${
                                    selectedLanguage.code === language.code ? 'bg-gray-100' : ''
                                    }`}
                                >
                                    {language.name}
                                </button>
                                ))}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
            </Popover>
        </div>
    );
};