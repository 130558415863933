import React, {useEffect, useState} from 'react';


type Props = {
    checked?: boolean,
    onChange: (checked: boolean) => void
};

export const Checkbox: (props: Props) => JSX.Element = ({ checked: checkedProvided, onChange }) => {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (checkedProvided === undefined) {
            return;
        }

        setChecked(checkedProvided);
    }, [checkedProvided]);

    return (
        <input
            type="checkbox"
            className="rounded default:text-bk-grey-100 checked:text-bk-p w-5 h-5 cursor-pointer"
            checked={checked}
            onChange={(event) => {
                setChecked(event.target.checked);
                onChange(event.target.checked);
            }}
        />
    );

};
