import React from 'react';

type Props = {
    noPadding?: boolean,
    small?: boolean,
    shadow?: boolean,
    className?: string,
    children: React.ReactNode
}

export const Block: (props: Props) => JSX.Element =
    ({
         noPadding,
         small,
         shadow = true,
         className,
         children
     }) => {
    return <div className={'rounded-lg p-7 ' + (noPadding ? 'p-0 ' : '') +
        (small ? 'bk-block-small-padding ' : '') +
        (shadow ? 'shadow-[0px_2px_4px_2px_rgba(100,111,122,0.05)] ' : '') +
        (className ? className : '')}>
        {children}
    </div>;
};
