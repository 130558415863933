import React, { useState, useEffect } from 'react';
import { Form, Block, Text, Button } from '../components';
import { api } from '../_library';
import { formatDate } from '../_library';
import { FormattedMessage } from "react-intl";


export const DuplicateFields = props => {

    const [items, setItems] = useState([]);
    const [duplicateItemIdx, setDuplicateItemIdx] = useState(null);
    const [unexpectedError, setUnexpectedError] = useState(false);

    const handleSubmit = (values, actions) => {

        const item = Object.assign({}, values);
        setUnexpectedError(false);

        const itemUpdate = () => {
            if (duplicateItemIdx === null) {  //item creation
                items.push(item);
                actions.resetForm();
            } else { //item edit
                items[duplicateItemIdx] = item;
                setDuplicateItemIdx(null);
            }

            setItems([...items]);
        };

        if (props.handleSubmit !== undefined) {
            props.handleSubmit({...values}, duplicateItemIdx, () => {
                if (duplicateItemIdx === null) {  //item creation
                    actions.resetForm();
                } else { //item edit
                    setDuplicateItemIdx(null);
                }

            }, actions);
        } else {
            const itemsData = [...items];
            if (duplicateItemIdx === null) { //item creation
                itemsData.push(item);
            } else { //item edit
                itemsData[duplicateItemIdx] = item;
            }

            const data = {
                step: props.step,
                [props.itemKey]: itemsData
            };

            if (props.beforeSubmit) {
                if (!props.beforeSubmit(values, actions)) {
                    actions.setSubmitting(false);
                    return;
                }
            }

            api.post(props.url, data)
                .then(() => {
                    itemUpdate();
                    props.onSubmitTriggered();
                })
                .catch(response => {
                    actions.setSubmitting(false);

                    if (!response.error) {
                        setUnexpectedError(true);
                    }
                });
        }
    };

    const renderForm = (validationSchema, inputFields, initValues, submitButtonLabel, noSubmitBtn, secondaryButton) => {
        return <Form
            initValues={initValues}
            formEnableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            formButton={submitButtonLabel}
            secondaryButton={secondaryButton !== undefined ? secondaryButton : false}
            previewImage={true}
            noSubmitBtn={noSubmitBtn}
            fields={inputFields}
            showGeneralErrorOnSubmit={true}
            submitBtnLabel="bk.duplicate_item.btn.submit"
        />
    };

    const renderDuplicateItems = (duplicateItems, propsDisplayFields, propsInputFields, propsValidationSchema) => (
        <>
            {duplicateItems && duplicateItems.map((item, idx) => {
                    if (duplicateItemIdx === idx) {
                        return(
                            <Block small shadow key={idx} className="mt-3 mb-3">
                                <Text left className="text-xl text-black font-bold mb-8">
                                    <FormattedMessage id={props.title}/> {idx + 1}
                                </Text>
                                {renderForm(
                                    propsValidationSchema,
                                    propsInputFields,
                                    item,
                                    undefined,
                                    props.secondaryButton,
                                    handleSubmit,
                                    'bk.duplicate_item.btn.cancel',
                                    () => setDuplicateItemIdx(null)
                                )}
                            </Block>
                        );
                    }

                    return(
                        <Block small shadow key={idx} className="mt-3 mb-5">
                            <Text left className="text-xl text-black font-bold mb-8">
                                <FormattedMessage id={props.title}/> {idx + 1}
                            </Text>
                            {propsDisplayFields.map((displayField, displayFieldIdx) => (
                                    <div key={displayFieldIdx}>
                                        <Text bold label={displayField.label}/>:
                                        {displayField.type === 'image' &&
                                            <img className="bk-duplicate-item-image ml-2" src={item[displayField.name]} />
                                        }
                                        {displayField.type === 'text' &&
                                            <Text light className="ml-1">{item[displayField.name]}</Text>
                                        }
                                        {(displayField.type === 'input' || displayField.type === 'textarea') &&
                                            <Text light className="ml-1">{item[displayField.name]}</Text>
                                        }
                                        {displayField.type === 'date' &&
                                            <Text light className="ml-1">{formatDate(item[displayField.name])}</Text>
                                        }
                                        {displayField.type === 'radio' &&
                                            <Text light className="ml-1">
                                                <FormattedMessage id={displayField.fields[item[displayField.name]]}/>
                                            </Text>
                                        }
                                    </div>
                                )
                            )}
                            <div className="text-right">
                                <Button small className="mt-3" label="bk.duplicate_item.btn.edit" onClick={() => setDuplicateItemIdx(idx)} />
                            </div>
                        </Block>
                    );
                }
            )}
        </>
    );

    const updateItems = () => {
        if (props.items !== undefined && props.items !== null) {
            setItems(props.items);
        }
    };

    useEffect(() => {
        updateItems();
    }, []);

    useEffect(() => {
        updateItems();
    }, [props.items]);

    return (
        <>
            {unexpectedError &&
                <Text label="bk.common.unexpected_error" />
            }
            {renderDuplicateItems(
                items,
                props.displayFields,
                props.inputFields,
                props.validationSchema,
            )}
            {renderForm(
                props.validationSchema,
                props.inputFields,
                props.initValues,
                props.submitButtonLabel,
                props.noSubmitBtn,
            )}
        </>
    );

};