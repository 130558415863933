import React, { useState } from 'react';
import { ReactComponent as ApiIcon } from '../assets/img/icons/API.svg';
import { ReactComponent as DownloadIcon } from '../assets/img/icons/Download.svg';
import { ReactComponent as PencilIcon } from '../assets/img/icons/Pencil.svg';
import { ReactComponent as ArrowIcon } from '../assets/img/icons/Arrow.svg';
import { ReactComponent as AddUserIcon } from '../assets/img/icons/AddUser.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/Search.svg';
import { ReactComponent as CloseIcon } from '../assets/img/icons/Close.svg';
import { ReactComponent as BackArrowIcon } from '../assets/img/icons/ArrowBack.svg';
import { ReactComponent as InfoIcon } from '../assets/img/icons/Info.svg';


type Props = {
    type: 'api' | 'download' | 'arrow' | 'pencil' | 'addUser' | 'search' | 'close' | 'backArrow' | 'info',
    children?: React.ReactNode,
    onClick?: () => void,
    className?: string,
    text?: string | React.ReactNode
}

export const Icon: (props: Props) => JSX.Element =
    ({
         type,
         onClick,
         children,
         className,
         text
    }) => {

    const [showTooltip, setShowTooltip] = useState(false);

    let IconSvg;

    switch(type) {
        case 'api':
            IconSvg = ApiIcon;
            break;
        case 'download':
            IconSvg = DownloadIcon;
            break;
        case 'arrow':
            IconSvg = ArrowIcon;
            break;
        case 'pencil':
            IconSvg = PencilIcon;
            break;
        case 'addUser':
            IconSvg = AddUserIcon;
            break;
        case 'search':
            IconSvg = SearchIcon;
            break;
        case 'close':
            IconSvg = CloseIcon;
            break;
        case 'backArrow':
            IconSvg = BackArrowIcon;
            break;
        case 'info':
            IconSvg = InfoIcon;
            break;
        default:
            IconSvg = 'span';
    }

    const IconElement = (
        <IconSvg onClick={(onClick ? onClick : () => {})} className={'' + (className || '')} />
    );

    if (text) {
        return (
            <div className="flex items-center ml-1">
                <IconSvg
                    className="inline-block"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                />
                {showTooltip &&
                    <span className="ml-4 rounded shadow-lg p-1 bg-gray-700 text-white text-xs whitespace-nowrap z-10 absolute">
                        {text}
                    </span>
                }
            </div>
        );
    }

    if (children) {
        return (
            <div className="d-inline-block position-relative">
                {IconElement}
                {children}
            </div>
        );
    }

    return IconElement;
};
