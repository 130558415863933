import React from 'react';
import { useNavigate } from 'react-router-dom';
import {getSignupStepUrlFromKycStatus, store} from '../_library';
import config from '../config';
import { LoadingWithSuspense } from './LoadingWithSuspense';

type Props = {
    Component: any,
    minimalRole?: number,
    exactRole?: number,
    [key: string]: any
};

export const PrivateRoute: (props: Props) => JSX.Element | null = ({ Component, minimalRole = 0, exactRole, ...rest }) => {

    const navigate = useNavigate();

    if (!store.getState().user.synchronized) {
        return null;
    }

    const token = localStorage.getItem(config.accessTokenName);

    if (!store.getState().user.loggedIn && !token) {
        navigate('/login');
        return null;
        // return <Navigate to={{pathname: '/login', state: {from: props.location}}}/>;
    } else if (!store.getState().user.currentKycStatus || config.kycStatus[store.getState().user.currentKycStatus] < config.kycStatus['filled']) {
        navigate(getSignupStepUrlFromKycStatus(store.getState().user.currentKycStatus));
        return null;
    } else if ((exactRole === undefined && (config.userRoles as any)[store.getState().user.role] >= minimalRole) ||
        (exactRole !== undefined && (config.userRoles as any)[store.getState().user.role] === exactRole)) {
        return <LoadingWithSuspense Component={Component} props={rest} />;
        // return <>{element}</>;
    } else {
        navigate('/login');
        return null;
        // return <Navigate to={{pathname: '/dashboard', state: {from: props.location}}}/>;
    }
}
