import { userConstants } from '../_constants';
import { api, getSignupStepUrlFromKycStatus, StoreDispatchType } from '../_library';
import config from '../config';
import { i18nActions } from './i18n.actions';


export const userActions = {
    loadSettings,
    login,
    logout,
    switchClient
};

function loadSettings() {
    return (dispatch: StoreDispatchType) => {

        const lang = localStorage.getItem(config.language) || 'en';

        dispatch(i18nActions.changeLanguage(lang));

        api.get('/users/profile')
            .then(res => {
                dispatch(success(res.data));
                dispatch(i18nActions.changeLanguage(res.data.language));
            }).catch(() => {
                localStorage.removeItem(config.accessTokenName);
                dispatch(reset());
                // dispatch(success({}));
            });
    };

    function success(data: any) { return { type: userConstants.LOAD_SETTINGS_SUCCESS, data } }
    function reset() { return { type: userConstants.RESET_SETTINGS } }
}

function login(noRedirect = false) {
    return (dispatch: StoreDispatchType) => {
        api.get('/users/profile')
            .then((res) => {
                dispatch(success(res.data));

                if (noRedirect) {
                    return;
                } else {
                    //TODO: michael: add history or do it another way
                    window.location.pathname = getSignupStepUrlFromKycStatus(res.data.currentKycStatus)
                }
            }).catch(error => {
                dispatch(logout());
                dispatch(failure(error));
            });
    };

    function success(data: any) { return { type: userConstants.LOAD_SETTINGS_SUCCESS, data } }
    function failure(error: any) { return { type: userConstants.LOAD_SETTINGS_FAILURE, error } }
}

function logout() {
    return (dispatch: StoreDispatchType) => {

        api.post('/logout')
            .then(() => {
                localStorage.removeItem(config.accessTokenName);
                dispatch(success());
                //TODO: michael: add history or do it another way
                window.location.pathname = '/';
                // redirect('/');
            })
            .catch(() => {
                localStorage.removeItem(config.accessTokenName);
                dispatch(success());
                //TODO: michael: add history or do it another way
                window.location.pathname = '/';
                // redirect('/');
            });
    };

    function success() { return { type: userConstants.RESET_SETTINGS } }
}

function switchClient(clientId: number, role: string) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: userConstants.SWITCH_CLIENT, data }
    })({clientId, role}));
    window.location.pathname = '/dashboard';
  };
}
