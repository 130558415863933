import React, { useState, useEffect, useRef } from 'react';
import {Button, Icon, Text} from './';
import { useIntl } from 'react-intl';


function useOutsideClickHandler(ref, outsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                outsideClick();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const InputSelect = props => {

    const intl = useIntl();
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(null);
    const [selectedName, setSelectedName] = useState('');
    const [selectedList, setSelectedList] = useState([]);
    const [optionName, setOptionName] = useState('name');
    const [optionValue, setOptionValue] = useState('value');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [initialized, setInitialized] = useState(false);

    const wrapperRef = useRef(null);

    useOutsideClickHandler(wrapperRef, () => {
        setOpened(false);

        if (selected) {
            const selectedOption = props.options.find(o => o[optionValue] === selected);
            if (selectedOption !== undefined) {
                setInputValue(selectedOption[optionName]);
            }
        }
    });

    const handleInputValueChange = val => {
        setInputValue(val);
        if (!opened) {
            setOpened(true);
        }
        onChange(val);
    };

    const onChange = props.onChange ? props.onChange : () => {};

    useEffect(() => {
        if (props.selected) {

            if (props.multi) {
                setSelectedList(props.selected);
            } else {
                setSelected(props.selected);
                const name = props.optionName ? props.optionName : optionName;
                const value = props.optionValue ? props.optionValue : optionValue;
                const selectedOption = props.options.find(o => o[value] === props.selected);
                if (selectedOption !== undefined) {
                    setInputValue(selectedOption[name]);
                    setSelectedName(selectedOption[name]);
                }
            }
        } else {
            setInputValue('');
            setSelectedName('');
        }
    }, [props.selected]);

    useEffect(() => {
        setOptions(props.options);
        setSelected(null);
    }, [props.options]);

    useEffect(() => {

        if (opened) {
            const name = props.optionName ? props.optionName : optionName;

            setOptions(props.options.filter(o => o[name].toLowerCase().includes(inputValue.toLowerCase())));
        } else {
            const name = props.optionName ? props.optionName : optionName;

            if (inputValue !== undefined) {
                setOptions(props.options.filter(o => o[name].toLowerCase().includes(inputValue.toLowerCase())));
            }
        }

        if (selected && inputValue === '') {
            setSelected(null);
        }

        if (inputValue !== '' && inputValue !== undefined) {
            const name = props.optionName ? props.optionName : optionName;
            const val = props.optionValue ? props.optionValue : optionValue;
            const idx = props.options.findIndex(o => o[name].toLowerCase() === inputValue.toLowerCase());

            if (idx !== -1) {
                setSelected(props.options[idx][val]);
                setSelectedName(props.options[idx][name]);
                onChange(props.options[idx][val]);
            }
        } else if (inputValue === '') {
            onChange('');
        }

    }, [inputValue]);

    useEffect(() => {

        if (!initialized && props.selected) {
            const value = props.optionValue ? props.optionValue : optionValue;
            const selectedOption = props.options.find(o => o[value] === props.selected);

            if (selectedOption !== undefined) {
                onChange(selectedOption[value]);
            } else {
                onChange('');
            }
            setInitialized(true);
        }

    }, [props.selected, inputValue, props.options]);

    useEffect(() => {
        // if (!opened) {
            setOptions(props.options);
        // }
    }, [opened]);

    useEffect(() => {
        setOptions(props.options);

        if (props.optionName) {
            setOptionName(props.optionName);
        }

        if (props.optionValue) {
            setOptionValue(props.optionValue);
        }
    }, []);

    const handleOptionSet = option => {

        if (props.multi) {
            const index = selectedList.indexOf(option[optionValue]);

            if (index > -1) {
                selectedList.splice(index, 1);
            } else {
                selectedList.push(option[optionValue]);
            }

            const newList = [...selectedList];
            setSelectedList(newList);
            onChange(newList);
        } else {
            setSelected(option[optionValue]);
            setSelectedName(option[optionName]);
            setInputValue(option[optionName]);
            onChange(option[optionValue]);
            setOpened(false);
        }
    };

    const checkOptionSelected = (optionValue) => {
        if (props.multi) {
            return selectedList.indexOf(optionValue) !== -1;
        } else {
            return selected === optionValue;
        }
    };

    let placeholder = '';

    if (props.multi) {
        if (selectedList.length === 0) {
            placeholder = 'bk.placeholder.input_select.multiple_items_select';
        } else {
            placeholder = 'bk.placeholder.input_select.multiple_items_selected';
        }
    } else if (!props.multi && props.placeholder) {
        placeholder = props.placeholder;
    }

    return(
        <>
            {props.label &&
                <>
                    <div className="hidden lg:block">
                        <div className="inline-block w-full">
                            <Text left bold label={props.label} />
                        </div>
                    </div>
                    <div className="block lg:hidden">
                        <Text left bold label={props.label} className="mt-10 mb-5" />
                    </div>
                </>
            }
            <div className={'relative border-0 ' +
            (props.fullWidth ? 'w-full ' : '') +
            (opened && options.length > 1 ? 'border-0 ' : '') +
            (props.search ? 'pl-0 ' : '') + (props.className ? props.className : '')
            } ref={wrapperRef}>
                {props.noInput ?
                    <Button
                        color="plainGrey"
                        onClick={() => setOpened(!opened)}
                        icon={<Icon type="arrow" className={opened ? 'rotate-180' : ''} />}
                        iconAfter={true}
                        smallPaddingOnSides={true}
                        noShadow={true}
                        className="min-w-[150px]"
                        disabled={props.disabled}
                    >
                        {inputValue}
                    </Button>
                    :
                    <input
                        {...props}
                        {...(props.textareaRows ? {rows: props.textareaRows} : {})}
                        {...(props.format ? {format: props.format} : {})}
                        type={props.type ? props.type : 'text'}
                        name={props.name}
                        id={props.name}
                        onChange={e => handleInputValueChange(e.target.value)}
                        onClick={() => setOpened(!opened)}
                        disabled={props.disabled}
                        value={inputValue}
                        {...(props.max ? {maxlength: props.max} : {})}
                        {...(props.value ? {value: props.value} : {})}
                        placeholder={placeholder ? intl.formatMessage({id: placeholder}) : ''}
                        className="h-12 bg-bk-grey-light border-0 rounded-md placeholder-gray-400 shadow-[0px_1px_2px_0px_rgba(0, 0, 0, 0.05)] block text-bk-black w-full sm:text-sm"
                    />
                }
                <div className={'shadow-md rounded mt-2 z-50 absolute overflow-y-scroll bg-white w-full ' + (opened ? 'block ' : 'hidden ') + (props.search ? 'bk-input-select-options-search ' : '') + (props.smallHeight ? 'max-h-24' : 'max-h-80')}>
                    {options.map((option, idx) => (
                            <div
                                key={idx}
                                className={
                                    'py-4 pl-4 pr-2 relative border-b border-grey-100 last:border-none cursor-pointer min-w-full hover:bg-bk-grey ' +
                                    (checkOptionSelected(option[optionValue]) ? 'bg-bk-grey' : '')
                                }
                                 onClick={() => handleOptionSet(option)}
                            >
                                {option[optionName]}
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

// InputSelect.protoTypes = {
//     options: PropTypes.array.isRequired,
//     multi: PropTypes.bool,
//     name: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     onChange: PropTypes.object,
//     placeholder: PropTypes.string,
//     selected: PropTypes.string,
//     optionName: PropTypes.string.isRequired,
//     optionValue: PropTypes.string.isRequired,
//     search: PropTypes.bool,
//     notInForm: PropTypes.bool,
//     noTopMargin: PropTypes.bool,
// };
