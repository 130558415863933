import React from 'react';

type Props = {
    className: string,
    dotted: boolean
}

export const Line: (props: Props) => JSX.Element = ({ className, dotted }) => {
    return (
        <hr className={className} style={{
                borderStyle: dotted ? 'dotted' : '',
                borderWidth: 1,
                borderRadius: 1,
            }}
        />
    );
};