type ConfigType = {
    env: string,
    accessTokenName: string,
    userRoles: {guest: number, accountant: number, manager: number, owner: 3},
    kycStatus: {[key: string]: number},
    language: string,
    baseUrl?: string,
    itemsPerPage: number
};

let config: ConfigType = {
    env: 'prod',
    accessTokenName: 'access_token',
    userRoles: {
        'guest': 0,
        'accountant': 1,
        'manager': 2,
        'owner': 3
    },
    kycStatus: {
        'not_filled': 0,  // no info filled at all
        'general': 1,  // general info filled
        'representatives': 2,  // representatives info filled
        'owners': 3,  // owners info filled
        'true_beneficiary': 4,  // true beneficiary info filled
        'political_importance': 5,  // political importance info filled
        'filled': 6,  // financial information info filled
    },
    language: 'language',
    itemsPerPage: 10
};

if (config.env === 'prod') {
    config['baseUrl'] = 'https://api.business.bonusukarte.lv';
} else if (config.env === 'dev') {
    config['baseUrl'] = 'https://api.dashboard.bk.micron.lv';
} else {
    config['baseUrl'] = 'http://127.0.0.1:5001';
}

export default Object.freeze(Object.assign({}, config));
