import React from 'react';
import { ReactComponent as WavesImg } from '../assets/img/waves.svg';
import { ReactComponent as LogoImg } from '../assets/img/icons/Logo.svg';
import { Button, Text } from './';
import { useNavigate } from 'react-router-dom';

type Props = {
    children: React.ReactNode,
    titleLabel: string,
    additionalBlockLabel?: string,
    additionalBlockBtnLabel?: string,
    additionalBlockBtnLink?: string
};

export const AuthFormWrap: (props: Props) => JSX.Element =
    ({
         children,
         titleLabel,
         additionalBlockLabel,
         additionalBlockBtnLabel,
         additionalBlockBtnLink
    }) => {

    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex justify-center bg-bk-grey py-12 px-4 sm:px-6 lg:px-8 relative">
            <div className="max-w-md w-full z-10">
                <div className="w-full flex justify-center">
                    <LogoImg/>
                </div>
                <Text label={titleLabel} center={true} className="text-2xl mt-6 mb-8"/>
                <div className="w-full space-y-4 bg-white p-10 rounded-lg">
                    {children}
                </div>
                {additionalBlockBtnLabel && additionalBlockBtnLink ?
                    <div className="mt-10">
                        {additionalBlockLabel ?
                            <Text center={true} label={additionalBlockLabel} className="text-md"/>
                            : null
                        }
                        <Button secondary={true} label={additionalBlockBtnLabel} className="mx-auto mt-6"
                                onClick={() => navigate(additionalBlockBtnLink)}/>
                    </div>
                    : null
                }
            </div>
            <WavesImg className="absolute left-0 bottom-0 w-full h-auto z-0"/>
        </div>
    );
};
