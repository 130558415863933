import { userConstants } from '../_constants';


const initialState = {
    loggedIn: false,
    synchronized: false,
    email: '',
    name: '',
    surname: '',
    role: 'guest',
    status: 'created',
    currency: 'EUR',
    language: 'lv',
    clients: [],
    availableFeatures: {} as {[key: string]: any}
};

export function user(state = Object.assign({}, initialState), action: any) {
    switch (action.type) {
        case userConstants.LOAD_SETTINGS_SUCCESS:
            state.loggedIn = true;
            state.synchronized = true;

            const currentClient: any = action.data.clients?.find((c: any) => c.id === action.data.currentClient);

            return Object.assign({}, {...state, ...action.data, role: action.data.role,
                currentClient: action.data.currentClient, availableFeatures: currentClient?.availableFeatures ? currentClient?.availableFeatures : {}});
        case userConstants.LOAD_SETTINGS_FAILURE:
            return Object.assign({...state}, action.data);
        case userConstants.RESET_SETTINGS:
            return Object.assign({}, {...initialState, synchronized: true});
        case userConstants.SWITCH_CLIENT:
            const client: any = state.clients.find((c: any) => c.id === action.data.clientId);

            return Object.assign({...state}, {currentClient: action.data.clientId, role: action.data.role, availableFeatures: client?.availableFeatures ? client?.availableFeatures : {}});
        default:
            return state;
    }
}
