import React, {Suspense} from 'react';
import { Loader } from './';

type Props = {
    Component: any,
    props?: {[key: string]: any}
}

export const LoadingWithSuspense: (props: Props) => JSX.Element = ({Component, props}) => {
    return (
        <Suspense fallback={
            <div className="mt-3">
                <Loader />
            </div>
        }>
            <Component {...props} match={props?.match} />
        </Suspense>
    );
};
