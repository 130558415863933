import React, { useState, useCallback, useEffect } from 'react';
import { Form, FormLabel, Icon } from './';
import { useField } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';


export const FormSelectList = props => {

    const [field, meta] = useField(props.name);
    const [inputOptions, setInputOptions] = useState([]);
    const initValues = {item: ''};

    const [inputFields, setInputFields] = useState([
        {name: 'item', type: 'select', label: props.inputLabel, options: props.inputOptions, notLabels: props.inputNotLabels, keyName: 'display'}
    ]);

    const validationSchema = {
        item: Yup.string()
            .oneOf(props.inputOptions.map(o => o.value.toString()))
            .required(<FormattedMessage id="bk.placeholder.validation.required" />)
    };

    const handleSubmit = useCallback((values, actions) => {
        const newValue = [...field.value];
        newValue.push(values.item);
        props.onChange(props.name, newValue, true);
        const newInputOptions = (inputOptions.length > 0 ? [...inputOptions] : [...props.inputOptions]).filter(o => o.value.toString() !== values.item.toString());
        setInputOptions(newInputOptions);
        setInputFields([{...inputFields[0], options: newInputOptions}]);
        actions.resetForm();
    }, [inputFields]);

    const removeItem = idx => {
        const newValue = [...field.value];
        const removableValue = newValue[idx];
        newValue.splice(idx, 1);
        props.onChange(props.name, newValue, true);
        const newInputOptions = [...inputOptions];

        if (newInputOptions.findIndex(o => o.value.toString() === removableValue.toString()) === -1) {
            newInputOptions.push(props.inputOptions.find(o => o.value.toString() === removableValue));
        }

        setInputOptions(newInputOptions);
        setInputFields([{...inputFields[0], options: newInputOptions}]);
    };

    useEffect(() => {

        if (inputOptions.length === 0) {
            const newInputOptions = [...props.inputOptions];

            field.value.forEach(i => {
                const idx = newInputOptions.findIndex(o => o.value.toString() === i.toString());
                if (idx !== -1) {
                    newInputOptions.splice(idx, 1);
                }
            });

            setInputOptions(newInputOptions);
            setInputFields([{...inputFields[0], options: newInputOptions}]);
        }
    }, [field.value]);

    return (
        <div>
            <FormLabel label={props.label} name={props.name} />
            <div className="rounded shadow-sm border p-3">
                {field.value.map((input, idx) => (
                        <div key={props.key + '_' + idx} className="flex mt-3">
                            {/*<CheckCircleIcon className="w-5 h-6 text-green-500 mr-3 flex-none" />*/}
                            <div className="break-all">{props.inputOptions.find(o => o.value.toString() === input).display}</div>
                            <div className="flex-grow flex flex-row-reverse">
                                <Icon type="close" className="w-5 h-6 ml-5 text-gray-500 cursor-pointer hover:text-bk-p-d" onClick={() => removeItem(idx)} />
                            </div>
                        </div>
                    )
                )}
                <Form
                    className="mt-3"
                    enableReinitialize={true}
                    initValues={initValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={inputFields}
                    noSubmitButton
                    submitBtnSmall
                    submitBtnSecondary
                    submitBtnLabel="bk.placeholder.btn.add"
                />
            </div>
        </div>
    );
};
//
// FormSelectList.propTypes = {
//     name: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     key: PropTypes.number.isRequired,
//     onChange: PropTypes.func.isRequired,
//     inputPlaceholder: PropTypes.string.isRequired,
//     inputLabel: PropTypes.string.isRequired,
//     inputOptions: PropTypes.array.isRequired,
//     meta: PropTypes.object.isRequired
// };

